<template>
    <div class="search_main scrollbar-class">
<!--        <transition :name="transitionName">-->
<!--            <findAdvertising v-if="$store.state.searchType=='findAdvertising'"></findAdvertising>-->
<!--            <findWord v-else-if="$store.state.searchType=='findWord'"></findWord>-->
<!--            <findCompany v-else-if="$store.state.searchType=='findCompany'"></findCompany>-->
<!--        </transition>-->
        <div id="tab_box">
         <span
             class="tab_item active"
             v-if="$route.name == 'findAdvertising'"
         >
          <img src="@/assets/img/search/icon_tab1_a.png" alt="" />
          <span>查广告创意</span>
        </span>
            <span class="tab_item" v-else @click="$router.push('/box/search/findAdvertising')">
          <img src="@/assets/img/search/icon_tab1.png" alt="" />
          <span>查广告创意</span>
        </span>
            <span
                class="tab_item active"
                v-if="$route.name == 'findWord'"
            >
          <img style="width: 17px; height: 19px" src="@/assets/img/search/icon_tab2_a.png" alt="" />
          <span>查关键词</span>
        </span>
            <span class="tab_item" v-else @click="$router.push('/box/search/findWord')">
          <img style="width: 17px; height: 19px" src="@/assets/img/search/icon_tab2.png" alt="" />
          <span>查关键词</span>
        </span>
            <span
                class="tab_item active"
                v-if="$route.name == 'findCompany'"
            >
          <img src="@/assets/img/search/icon_tab3_a.png" alt="" />
          <span>查推广公司</span>
        </span>
            <span class="tab_item" v-else @click="$router.push('/box/search/findCompany')">
          <img src="@/assets/img/search/icon_tab3.png" alt="" />
          <span>查推广公司</span>
        </span>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
 import findAdvertising from "./findAdvertising";
 import findWord from "./findWord";
 import findCompany from "./findCompany";
 import PinganAna from 'pingansec-vue-ana'
  export default {
    name: "index",
    components: {
      findAdvertising,
      findCompany,
      findWord
    },
    created() {
      PinganAna.fire(3094)
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
.search_main {
    height: 100%;
    overflow-y: scroll;
    #tab_box {
        width: calc(100% - 182px);
        height: 54px;
        position: absolute;
        right: 0;
        top: 70px;
        background-color: #ffffff;
        border-top: 2px solid #f0f5f9;
        box-shadow: 0px 3px 5px 0px rgba(212, 219, 227, 0.3);
        display: flex;
        align-items: center;
        z-index: 11;

        .tab_item {
            display: flex;
            align-items: center;
            margin-left: 57px;
            cursor: pointer;
            height: 100%;
            border-bottom: 2px solid #ffffff;
            box-sizing: border-box;

            img {
                width: 18px;
                height: 18px;
            }

            span {
                margin-left: 9px;
                font-size: 16px;
                font-weight: bold;
                color: #333333;
            }
        }

        .active {
            border-bottom: 2px solid #1f81f8;

            span {
                color: #1f81f8;
            }
        }
    }
}
</style>
